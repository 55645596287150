import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async'
import './Styles/index.css';
import './Styles/Sections.css';
import { Master } from './Master'


//import './index-android.css' 




const helmetContext = {};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//const rootElement: any = document.getElementById("root") as HTMLElement;
//console.log('rootElement', rootElement)

root.render(<HelmetProvider>
  <Master></Master>
</HelmetProvider>)
/*
if (rootElement.hasChildNodes()) {
  console.log('tyty 11')

  hydrate(<HelmetProvider>
    <Master></Master>
  </HelmetProvider>, rootElement);
} else {
  console.log('tyty')
  root.render(
    <HelmetProvider>
      <Master></Master>
    </HelmetProvider>
  )
}
*/
