





import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';



type props = {
    loading: any
}


export const BackDropLoading = ({ loading }: props) => {

    return (

        <Backdrop
            sx={{ color: '#1017204c', zIndex: (theme:any) => theme.zIndex.drawer + 1 }}
            open={loading}
            onClick={() => { }}
        >
            <CircularProgress style={{zIndex: "8"}}/>
        </Backdrop>


    )
}







